import './styles/header.scss'
import './styles/footer.scss'
import './styles/simple.scss'
import './styles/home.scss'
import './public/terms-and-conditions.pdf'
import './public/algemene-voorwaarden.pdf'
import './public/browserconfig.xml'
import {downloadAndroidClicked, downloadIosClicked, sendLinkClicked} from './util/analytics'
import parsePhoneNumber from 'libphonenumber-js'
import countries from './util/countries'
import './util/language-switcher'
import './util/fader'

document.getElementById('cta_android').onclick = () => downloadAndroidClicked()
document.getElementById('cta_ios').onclick = () => downloadIosClicked()

const sendLinkContainer = document.getElementById('send-link-container')
const selectCountryCode = document.getElementById('select-country-code')
const inputNumber = document.getElementById('input-number')
const sendLinkButton = document.getElementById('cta_link')
const sendLinkSuccess = document.getElementById('send-link-success')

inputNumber.onkeydown = e => {
  inputNumber.classList.remove('invalid')
  if(e.key === 'Enter') {
    submitPhoneNumber()
  }
}

countries.forEach(country => {
  const el = document.createElement("option")
  el.textContent = `${country.ccode} ${country.mcode}`
  el.value = country.value
  selectCountryCode.appendChild(el)
})
selectCountryCode.value = '31'

const submitPhoneNumber = e => {
  if (e) e.preventDefault();
  const phoneNumberValue = `${selectCountryCode.value}${inputNumber.value}`
  const phoneNumber = parsePhoneNumber(phoneNumberValue, 'NL')
  if (!phoneNumber.isValid()) {
    inputNumber.classList.add('invalid')
    return
  }
  const sanitised = phoneNumber.number.replace('+','')
  sendLinkButton.disabled = true
  sendLinkClicked()
  grecaptcha.ready(async () => {
    try {
      const token = await grecaptcha.execute(process.env.recaptcha_key, {action: 'send_link'})
      const response = await fetch(`${process.env.api_url}/requestDownloadLink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token,
          phoneNumber: sanitised
        })
      })
      sendLinkContainer.style.display = 'none'
      sendLinkSuccess.style.display = 'block'
    } catch (e) {
      console.log('Something went wrong', e)
      sendLinkButton.disabled = false
    }
  });
}

sendLinkButton.onclick = submitPhoneNumber

// Hide on iOS
let iOS = /(iPhone|iPad|iPod)/g.test(navigator.userAgent);
let android = /(android)/i.test(navigator.userAgent);
[].slice.call(document.querySelectorAll('.hide-ios')).forEach(
  function(ele) {
    if (iOS) {
      ele.style.display = "none"
    }
  }
);
[].slice.call(document.querySelectorAll('.hide-android')).forEach(
  function(ele) {
    if (android) {
      ele.style.display = "none"
    }
  }
);