(async function (h, o, t, j, a, r) {
  h.hj = h.hj || function () {
    (h.hj.q = h.hj.q || []).push(arguments)
  }
  h._hjSettings = {hjid: process.env.hotjar_id, hjsv: 6}
  a = o.getElementsByTagName('head')[0]
  r = o.createElement('script')
  r.async = 1
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  a.appendChild(r)
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

export const sendEvent = (name, category, label) => {
  gtag('event', name, {
    'event_category': category,
    'event_label': label
  })
}

export const sendLinkClicked = () => sendEvent('Download Desktop', 'DownloadClick', 'Home')
export const downloadAndroidClicked = () => sendEvent('Download Android', 'DownloadClick', 'Home')
export const downloadIosClicked = () => sendEvent('Download iOS', 'DownloadClick', 'Home')

export const redirectAndroid = () => sendEvent('Redirect Android', 'DownloadRedirect', 'Link')
export const redirectIos = () => sendEvent('Redirect iOS', 'DownloadRedirect', 'Link')
export const redirectDesktop = () => sendEvent('Redirect Desktop', 'DownloadRedirect', 'Link')

export const tryCtaClicked = location => sendEvent('Try BASH', 'CTA', location)
export const signInCtaClicked = location => sendEvent('Sign in', 'CTA', location)
export const seeDemoClicked = location => sendEvent('See demo', 'Demo', location)