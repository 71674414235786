import {setActive} from "./util"


Array.from(document.getElementsByClassName('fader')).forEach(fader => {
  const images = Array.from(fader.getElementsByClassName('fader-image'))
  let i = 1
  setInterval(() => {
    images.forEach((image, index) => {
      setActive(image, index === i % images.length)
    })
    i += 1
  }, 5000)
})