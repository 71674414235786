const en = document.getElementById('switch-en')
const nl = document.getElementById('switch-nl')

const switchLanguage = language => {
  document.cookie = `firebase-language-override=${language}`;
  location.reload()
}

if (en) {
  en.onclick = () => switchLanguage('en')
}
if (nl) {
  nl.onclick = () => switchLanguage('nl')
}